import styled from "styled-components"
import { Link } from "gatsby"

const ListItemLink = styled(Link)`
  text-decoration: none;

  &:after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
`

export default ListItemLink
