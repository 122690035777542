import styled from "styled-components"
import { colors, spacing } from "../style/theme"

const ListItem = styled.li`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${colors.bgLight};
  padding: ${spacing.medium};
  position: relative;

  @media (max-width: 650px) {
    flex-direction: column;
    align-items: flex-start;
  }
`

export default ListItem
