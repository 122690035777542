import React from "react"
import { StaticQuery, graphql } from "gatsby"

import Layout from "../../components/layout"
import List from "../../components/list"
import ListItem from "../../components/list-item"
import ListItemLink from "../../components/list-item-link"

export const papersQuery = graphql`
  query PapersQuery {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___title] }
      filter: { frontmatter: { templateKey: { eq: "paper" } } }
    ) {
      edges {
        node {
          id
          fields {
            slug
          }
          frontmatter {
            title
            # authors {
            #   name
            #   link
            # }
            templateKey
          }
        }
      }
    }
  }
`

interface PapersProps {
  allMarkdownRemark: {
    edges: {
      node: {
        id: string
        fields: {
          slug: string
        }
        frontmatter: {
          title: string
          templateKey: string
        }
      }
    }[]
  }
}
const Papers: React.FC = () => (
  <StaticQuery
    query={papersQuery}
    render={(props: PapersProps) => {
      const { allMarkdownRemark } = props
      const { edges } = allMarkdownRemark

      return (
        <Layout>
          <h1>Papers</h1>
          {!edges.length ? (
            <p>
              We&rsquo;ll soon be adding papers here in mid-November, so come
              back then!
            </p>
          ) : (
            <List>
              {edges.map(edge => {
                const {
                  node: {
                    id,
                    fields: { slug },
                    frontmatter: { title },
                  },
                } = edge
                return (
                  <ListItem key={`papers-paper-${id}`}>
                    <ListItemLink to={slug}>{title}</ListItemLink>
                  </ListItem>
                )
              })}
            </List>
          )}
        </Layout>
      )
    }}
  />
)

export default Papers
